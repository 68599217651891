<template>
  <div class="h-100">
    <router-view />
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
